.tabbar {
    @apply flex md:hidden flex-row items-center justify-between z-50 h-18 bg-white visible md:invisible fixed bottom-0 w-full text-2xl;
    box-shadow: 0px -5px 13px 0px rgba(0, 0, 0, 0.27);
}

.tabItem {
    @apply text-gray-400 hover:text-gray-700 cursor-pointer w-18 h-full flex items-center justify-center;
}

.tabItemActive {
    @apply bg-gradient-to-t from-fundo-gray-light to-fundo-gray border-t-2 border-fundo-purple text-fundo-purple-dark;
}

.icon {
    @apply -mb-1;
}
