.header {
    @apply bg-fundo-purple fixed w-full max-h-20 z-10;
}

.menuButton {
    @apply absolute inset-y-0 left-0 md:static md:flex-shrink-0;
}
.menuButton button {
    @apply flex items-center justify-center h-16 w-16 bg-fundo-purple  md:w-20;
}

.logo {
    @apply min-w-0 flex-1 px-3;
}
.logo img {
    @apply h-7;
}

.loginButton {
    @apply bg-fundo-blue text-white px-3 py-1 rounded;
}

.signupButton {
    @apply bg-fundo-purple-light text-white px-3 py-1 rounded;
}
