.navigationItem {
    @apply flex items-center rounded focus:outline-none focus:ring-2 focus:ring-white;
}

.navigationSubItem {
    @apply w-full;
}

.navigationSubItem .active {
    @apply text-fundo-purple-dark font-bold;
}
