.titleContainer {
    @apply w-full flex mb-4;
}
.titleContainer div {
    @apply w-1/2;
}
.titleContainer h2 {
    @apply font-light text-2xl;
}
.titleContainer .link {
    @apply text-fundo-gray-dark text-xl flex-grow text-right;
}

.amateurContainer {
    @apply flex flex-wrap;
}
.amateurItem {
    @apply w-1/2 md:w-1/4 lg:w-1/8 pr-2;
}

.amateurItem img {
    @apply border-transparent;
    border-radius: 50%;
    border-width: 0.3rem;
}

.amateurItem img.active {
    @apply border-green-600;
}
