.webCamItem {
    @apply pr-2 pb-2 cursor-pointer;
}

.webcamItemInner {
    @apply rounded-md overflow-hidden shadow-xl;
}

.webcamItemInner .imgContainer {
    @apply rounded-t-md;
}
.webcamItemInner .imgContainer img {
    @apply w-full rounded-t-md;
}

.webcamItemInner .metaContainer .wcIconContainer {
    @apply flex-none w-8 h-8;
}


.webcamItemInner .metaContainer .wcIconContainer svg {
    @apply flex-none w-6 h-6 fill-current;
}


.webcamItemInner .metaContainer .wcIconContainer svg.active {
    @apply text-green-600;
}

.webcamItemInner .metaContainer .wcIconContainer svg.inActive {
    @apply text-fundo-gray-dark;
}

.webcamItemInner .metaContainer {
    @apply flex bg-fundo-gray py-3 h-10;
}
